import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/pro-light-svg-icons'

import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
    currentPassword: '',
    passwordOne: '',
    passwordTwo: '',
    newEmail: '',
    newCompany: '',
    newCompanyAddress: '',
    error: null,
    errorEmail: null,
    lengthValid: 'invalid clearfix',
    specialChar: 'invalid clearfix',
    lowerCase: 'invalid clearfix',
    upperCase: 'invalid clearfix',
    validPassword: false,
  };

class UpdatePassword extends Component {
    constructor(props){
        super(props);
        this.state = { ...INITIAL_STATE };
    }
    componentDidMount() {

        var parent = document.getElementById("rel1");
        var slash = parent.getElementsByClassName('slash')[0];
        var reg = parent.getElementsByClassName('reg')[0];

        reg.style.display = "none";

        var parent2 = document.getElementById("rel2");
        var slash2 = parent2.getElementsByClassName('slash')[0];
        var reg2 = parent2.getElementsByClassName('reg')[0];

        reg2.style.display = "none";

        var parent3 = document.getElementById("rel3");
        var slash3 = parent3.getElementsByClassName('slash')[0];
        var reg3 = parent3.getElementsByClassName('reg')[0];

        reg3.style.display = "none";

        document.getElementById('togglePassword').onclick = function(e){
           var x = document.getElementById("passwordOne");

           if (x.type === "password") {
             x.type = "text";
             slash.style.display = "none";
             reg.style.display = "block";
           } else {
             x.type = "password";
             slash.style.display = "block";
             reg.style.display = "none";
           }
        }
        document.getElementById('togglePasswordConfirm').onclick = function(e){
           var x = document.getElementById("passwordTwo");
           if (x.type === "password") {
             x.type = "text";
             slash2.style.display = "none";
             reg2.style.display = "block";
           } else {
             x.type = "password";
             slash2.style.display = "block";
             reg2.style.display = "none";
           }
        }
        document.getElementById('toggleCurrentPassword').onclick = function(e){
          var x = document.getElementById("currentPassword");
          if (x.type === "password") {
            x.type = "text";
            slash3.style.display = "none";
            reg3.style.display = "block";
          } else {
            x.type = "password";
            slash3.style.display = "block";
            reg3.style.display = "none";
          }
       }
    }

    onClick = e => {
      e.preventDefault();
      this.changeModal.current.change();
    }

    onClickEN = e => {
      e.preventDefault();

        this.setState(this.English);
      }
      onClickFR = e => {
        e.preventDefault();
        this.setState(this.French);
      }
onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    var specialRegex = /[~`!@#$%^&()_={}[]:;,.1234567890<>+\/?-]/;
    var containsSpecial = false;
    var containsUpperCase = false;
    var containsLowerCase = false;
    var correctLength = false;

    if(specialRegex.test(event.target.value)){
      containsSpecial = true;
      this.setState({specialChar: 'valid clearfix'})
    }
    else{
      this.setState({specialChar: 'invalid clearfix'})
      containsSpecial = false;
    }
    if(/[a-z]/.test(event.target.value)){
      containsLowerCase = true;
      this.setState({lowerCase: 'valid clearfix'})
    }
    else{
      this.setState({lowerCase: 'invalid clearfix'})
      containsLowerCase = false;
    }
    if(/[A-Z]/.test(event.target.value)){
      containsUpperCase = true;
      this.setState({upperCase: 'valid clearfix'})
    }
    else{
      containsUpperCase = false;
      this.setState({upperCase: 'invalid clearfix'})
    }

    if(event.target.value.length >= 8 ){
      correctLength = true;
      this.setState({lengthValid: 'valid clearfix'})
    }
    else{
      correctLength = false;
      this.setState({lengthValid: 'invalid clearfix'})
    }
    if(containsSpecial && containsUpperCase && containsLowerCase && correctLength){
      this.setState({validPassword: true})
    }
    else{
      this.setState({validPassword: false})
    }
  };

  onChangeCurrentPassword = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

onchangePassword = event => {
    event.preventDefault();
    const { passwordOne, currentPassword } = this.state;
    this.props.firebase.doReAuthenticate(currentPassword).catch((error) => this.setState({ error }));
    if(this.state.error){
    this.props.firebase.doPasswordUpdate(passwordOne,currentPassword);
    }

  }
  onConfirmPassChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value !== this.state.passwordOne ){
      this.setState({ passMatch: false });
    }
    else if (event.target.value === this.state.passwordOne){
      this.setState({ passMatch: true });
    }
  }

  render() {
    const { passwordOne, passwordTwo, error, currentPassword } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

        return (
            <form id="fullWidthForm" className="validate-password" onSubmit={this.onchangePassword}>
            <div className="form-group password-group large">
            <label htmlFor="currentPassword">Enter Current Password</label>
              <div className="relative" id="rel3">
                <input
                  name="currentPassword"
                  id="currentPassword"
                  value={currentPassword}
                  onChange={this.onChangeCurrentPassword}
                  type="password"
                  placeholder="Current Password"
                />
                <div className="position-end">
                    <label htmlFor="toggleCurrentPassword" className="pointer"><span className="screen-reader">Show Password</span><FontAwesomeIcon className="slash" icon={faEyeSlash} /><FontAwesomeIcon className="reg" icon={faEye} /></label>
                    <input className="hidden" type="checkbox" name="toggleCurrentPassword" id="toggleCurrentPassword" />
                </div>
                {error && <label className="error">{error.message}</label>}
              </div>
              <label htmlFor="passwordOne">Enter New Password<sup>*</sup></label>
            <div className="relative" id="rel1">
                <input
                  name="passwordOne"
                  id="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                />
                <div className="position-end">
                    <label htmlFor="togglePassword" className="pointer"><span className="screen-reader">Show Password</span><FontAwesomeIcon className="slash" icon={faEyeSlash} /><FontAwesomeIcon className="reg" icon={faEye} /></label>
                    <input className="hidden" type="checkbox" name="togglePassword" id="togglePassword" />
                </div>
            </div>
            <div id="password-info">
                <ul id="password-info-list">
                    <li id="length" className={this.state.lengthValid}>
                        <span className="icon-container"></span>At least 8 characters
                    </li>
                    <li id="capital" className={this.state.upperCase}>
                        <span className="icon-container"></span>At least 1 uppercase letter
                    </li>
                    <li id="lowercase" className={this.state.lowerCase}>
                        <span className="icon-container"></span>At least 1 lowercase letter
                    </li>
                    <li id="number-special" className={this.state.specialChar}>
                        <span className="icon-container"></span>At least 1 number or <span title="&#96; &#126; &#33; &#64; &#35; &#36; &#37; &#94; &#38; &#42; &#40; &#41; &#43; &#61; &#124; &#59; &#58; &#39; &#34; &#44; &#46; &#60; &#62; &#47; &#63; &#92; &#45;" className="special-characters tip">special character</span>
                    </li>
                </ul>
            </div>
        </div>
        <div className="form-group password-group large clear-left">
            <div>
                <label htmlFor="passwordTwo">Confirm New Password<sup>*</sup></label>
                <div className="relative" id="rel2">
                    <input
                      title="Confirm Password"
                      name="passwordTwo"
                      id="passwordTwo"
                      value={passwordTwo}
                      onChange={this.onConfirmPassChange}
                      type="password"
                      placeholder="Confirm Password"
                    />
                    <div className="position-end">
                        <label htmlFor="togglePasswordConfirm" className="pointer"><span className="screen-reader">Show Password</span><FontAwesomeIcon className="slash" icon={faEyeSlash} /><FontAwesomeIcon className="reg" icon={faEye} /></label>
                        <input className="hidden" type="checkbox" name="togglePasswordConfirm" id="togglePasswordConfirm" />
                    </div>
                    <label htmlFor="passwordTwo" className="error" hidden={this.state.passMatch}>Please enter the same password was above</label>
                </div>
            </div>
        </div>
        <button disabled={isInvalid} type="submit">
              Change Password
        </button>
        </form>



        )
    }
}
  export default withFirebase(UpdatePassword);
