import React, { Fragment } from 'react';
import { compose } from 'recompose';

import Layout from '../App/components/layout';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';


import UpdatePassword from '../components/UpdateProfile/updatePassword';

import SEO from "../App/components/seo"

const PasswordPageBase = () => (

    <Fragment>
      <div class="wrapper maxWidth"><h1>Update Password</h1>
      <UpdatePassword/>
      </div>
    </Fragment>

  );

  const condition = authUser => !!authUser;

  const PasswordPage = compose(
    withEmailVerification,
    withAuthorization(condition),
  )(PasswordPageBase);

export default () =>(
    <Layout>
      <SEO title="" />
      <PasswordPage/>
    </Layout>
  );
